import React from "react";

import anime from "animejs/lib/anime.es.js";
import ReactInterval from "react-interval";
import { useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import "../HomePage.scss";

import img1 from "assets/img/pictures/beautiful-wedding-flower-arrangement-seats-along-aisle-2.jpg";
import img2 from "assets/img/pictures/birthday-cake-with-gift-accessories-blue-background.jpg";
import img3 from "assets/img/pictures/black-white-photo-cheerful-bride-groom-holding-hands-smiling-each-other-against-glowing-fireworks.jpg";
import img4 from "assets/img/pictures/dancing-concert-while-singer-is-performing-surrounded-with-lights.jpg";
import img5 from "assets/img/pictures/beautiful-wedding-flower-arrangement-seats-along-aisle.jpg";
import img6 from "assets/img/pictures/cute-infant-sleeping-with-grey-crocheted-hat-and-with-toy-rabbit.jpg";
import img7 from "assets/img/pictures/decorated-with-flowers-exit-wedding-ceremony-archway.jpg";
import img8 from "assets/img/pictures/flower-wedding-event.jpg";
import img9 from "assets/img/pictures/groom-holds-bride-s-hands-where-are-two-wedding-rings.jpg";
import img10 from "assets/img/pictures/holding-hands.jpg";
import img11 from "assets/img/pictures/young-man-bringing-ring-box-his-girlfriend-his-home.jpg";

const Slider = () => {
  const animationRef = React.useRef(null);
  const slideRef = React.useRef([[], [], []]);
  const images = React.useRef([[], [], []]);
  const textRef = React.useRef([[], [], []]);
  const [isActive, setIsActive] = React.useState([true, false, false]);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [check, setCheck] = React.useState(true);
  const [current, setCurrent] = React.useState(0);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    setCheck(false);
  }, []);

  const getRef = (element, index) => {
    if (check) {
      if (element) images.current[index].push(element);
    }
  };
  const getRef2 = (element, index) => {
    if (check) {
      if (element) textRef.current[index].push(element);
    }
  };
  const getRef3 = (element, index) => {
    if (check) {
      if (element) slideRef.current[index].push(element);
    }
  };

  let wrapers = [
    <div className="wrap">LaFacil</div>,
    <div className="wrap">LaFacil</div>,
    <div className="wrap">LaFacil</div>,
  ];
  let newWrapper = wrapers.map((e, index) => {
    let tmp = (
      <div className="wrap">
        {e.props.children.split("").map((c, k) => {
          return (
            <span key={k} ref={(e) => getRef2(e, index)} className="letter">
              {c}
            </span>
          );
        })}
      </div>
    );
    return tmp;
  });

  let items = [
    <div
      key={0}
      ref={(e) => getRef3(e, 0)}
      className={`item ${isActive[0] ? "is-active" : ""}`}
    >
      <div className="content">{newWrapper[0]}</div>
      <div className="imgs">
        <div className="grid">
          <div ref={(e) => getRef(e, 0)} className="img img-1">
            <img src={img7} />
          </div>
          <div ref={(e) => getRef(e, 0)} className="img img-2">
            <img src={img6} />
          </div>
          <div ref={(e) => getRef(e, 0)} className="img img-3">
            <img src={img3} />
          </div>
          <div ref={(e) => getRef(e, 0)} className="img img-4">
            <img src={img2} />
          </div>
        </div>
      </div>
    </div>,
    <div
      key={1}
      ref={(e) => getRef3(e, 1)}
      className={`item ${isActive[1] ? "is-active" : ""}`}
    >
      <div className="content">{newWrapper[1]}</div>
      <div className="imgs">
        <div className="grid">
          <div ref={(e) => getRef(e, 1)} className="img img-1">
            <img src={img5} />
          </div>
          <div ref={(e) => getRef(e, 1)} className="img img-2">
            <img src={img4} />
          </div>
          <div ref={(e) => getRef(e, 1)} className="img img-3">
            <img src={img8} />
          </div>
          <div ref={(e) => getRef(e, 1)} className="img img-4">
            <img src={img1} />
          </div>
        </div>
      </div>
    </div>,
    <div
      key={2}
      className={`item ${isActive[2] ? "is-active" : ""}`}
      ref={(e) => getRef3(e, 2)}
    >
      <div className="content">{newWrapper[2]}</div>
      <div className="imgs">
        <div className="grid">
          <div ref={(e) => getRef(e, 2)} className="img img-1">
            <img src={img9} />
          </div>
          <div ref={(e) => getRef(e, 2)} className="img img-2">
            <img src={img10} />
          </div>
          <div ref={(e) => getRef(e, 2)} className="img img-3">
            <img src={img11} />
          </div>
          <div ref={(e) => getRef(e, 2)} className="img img-4">
            <img src={img8} />
          </div>
        </div>
      </div>
    </div>,
  ];

  function anim(current, next, callback) {
    const duration = 400;
    const offset = "-=" + 300;
    const imgOffset = duration * 0.8;
    const tl = anime.timeline({
      easing: "easeInOutQuint",
      duration: duration,
      complete: callback,
    });
    //Add children
    tl.add({
      targets: textRef.current[current],
      translateY: [0, "-.75em"],
      /*clipPath: ['polygon(0 0, 100% 0, 100% 100%, 0% 100%)', 'polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)'],*/
      opacity: [1, 0],
      easing: "easeInQuint",
      duration: 600,
      delay: (el, i) => 10 * (i + 1),
    })
      .add(
        {
          targets: images.current[current][0],
          translateY: -600,
          rotate: [0, "-15deg"],
          opacity: [1, 0],
          easing: "easeInCubic",
        },
        offset
      )
      .add(
        {
          targets: images.current[current][1],
          translateY: -600,
          rotate: [0, "15deg"],
          opacity: [1, 0],
          easing: "easeInCubic",
        },
        "-=" + imgOffset
      )
      .add(
        {
          targets: images.current[current][2],
          translateY: -600,
          rotate: [0, "-15deg"],
          opacity: [1, 0],
          easing: "easeInCubic",
        },
        "-=" + imgOffset
      )
      .add(
        {
          targets: images.current[current][3],
          translateY: -600,
          rotate: [0, "15deg"],
          opacity: [1, 0],
          easing: "easeInCubic",
        },
        "-=" + imgOffset
      )
      .add({
        targets: slideRef.current[current],
        opacity: 0,
        duration: 10,
        easing: "easeInCubic",
      })
      .add(
        {
          targets: slideRef.current[next],
          opacity: 1,
          duration: 10,
        },
        offset
      )
      .add(
        {
          targets: images.current[next][0],
          translateY: [600, 0],
          rotate: ["15deg", 0],
          opacity: [0, 1],
          easing: "easeOutCubic",
        },
        offset
      )
      .add(
        {
          targets: images.current[next][1],
          translateY: [600, 0],
          rotate: ["-15deg", 0],
          opacity: [0, 1],
          easing: "easeOutCubic",
        },
        "-=" + imgOffset
      )
      .add(
        {
          targets: images.current[next][2],
          translateY: [600, 0],
          rotate: ["15deg", 0],
          opacity: [0, 1],
          easing: "easeOutCubic",
        },
        "-=" + imgOffset
      )
      .add(
        {
          targets: images.current[next][3],
          translateY: [600, 0],
          rotate: ["-15deg", 0],
          opacity: [0, 1],
          easing: "easeOutCubic",
        },
        "-=" + imgOffset
      )
      .add(
        {
          targets: textRef.current[next],
          translateY: [".75em", 0],
          /*clipPath: ['polygon(0 0, 100% 0, 100% 0, 0 0)','polygon(0 0, 100% 0, 100% 100%, 0% 100%)'],*/
          opacity: [0, 1],
          easing: "easeOutQuint",
          duration: 600,
          delay: (el, i) => 10 * (i + 1),
        },
        offset
      );
    animationRef.current = tl;
  }

  const updateSlider = (newIndex) => {
    const currentItem = current;
    const newItem = newIndex;

    function callback() {
      let tmpArr = [...isActive];
      tmpArr[current] = false;
      tmpArr[newItem] = true;
      setIsActive(tmpArr);
      setCurrent(newIndex);
      setIsPlaying(false);
    }
    anim(currentItem, newItem, callback);
  };
  const nextSlide = () => {
    if (isPlaying) return;
    setIsPlaying(true);
    const newIndex = current === items.length - 1 ? 0 : current + 1;
    updateSlider(newIndex);
  };
  const prevSlide = () => {
    if (isPlaying) return;
    setIsPlaying(true);
    const newIndex = current === 0 ? items.length - 1 : current - 1;
    updateSlider(newIndex);
  };

  return (
    <div>
      <ReactInterval timeout={7000} enabled callback={() => nextSlide()} />
      <div className="slider">
        <div className="nav">
          <div className="next" onClick={nextSlide}></div>
          <div className="prev" onClick={prevSlide}></div>
          <Link style={{ color: "#fff" }} smooth to="home#about-us">
            <div
              className="explore-btn"
              onClick={() => {
                history.push("/home#about-us");
              }}
              style={{
                fontFamily: i18n.language == "ar" ? "lfFont" : null,
              }}
            >
              {t("slides.btn")}
            </div>
          </Link>
        </div>
        {items}
      </div>
    </div>
  );
};

export default Slider;
