import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import Button from "@material-ui/core/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Fade from "react-reveal/Fade";

import mobileApp from "assets/img/mobile/app-design.png";

import { GrTask } from "react-icons/gr";
import { GrOptimize } from "react-icons/gr";
import { GrGroup } from "react-icons/gr";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

const AboutUs = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <a id="about-us" name="about-us"></a>
      <div className={classes.section}>
        <GridContainer
          style={{ marginTop: "20px" }}
          justify="center"
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
        >
          <GridItem xs={12} sm={12} md={4}>
            <Fade top delay={250}>
              <h2
                className={classes.title}
                style={{
                  fontFamily: i18n.language === "ar" ? "lfFont" : null,
                }}
              >
                {t("aboutus.header")}
              </h2>
              <h5
                className={classes.description}
                style={{
                  fontFamily: i18n.language === "ar" ? "lfFont" : null,
                }}
              >
                {t("aboutus.description")}
              </h5>
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Fade top delay={250}>
              <h2
                className={classes.title}
                style={{
                  fontFamily: i18n.language === "ar" ? "lfFont" : null,
                }}
              >
                {t("vision.header")}
              </h2>
              <h5
                className={classes.description}
                style={{
                  fontFamily: i18n.language === "ar" ? "lfFont" : null,
                }}
              >
                {t("vision.description")}
              </h5>
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Fade top delay={250}>
              <h2
                className={classes.title}
                style={{
                  fontFamily: i18n.language === "ar" ? "lfFont" : null,
                }}
              >
                {t("mission.header")}
              </h2>
              <h5
                className={classes.description}
                style={{
                  fontFamily: i18n.language === "ar" ? "lfFont" : null,
                }}
              >
                {t("mission.description")}
              </h5>
            </Fade>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Fade top delay={250}>
              <h2
                className={classes.title}
                style={{
                  fontFamily: i18n.language === "ar" ? "lfFont" : null,
                }}
              >
                {t("values.header")}
              </h2>
              <GridContainer style={{ marginTop: "20px" }} justify="center">
                <GridItem xs={12} sm={12} md={4}>
                  <GrTask
                    style={{
                      color: "#3C4858",
                      fontSize: "24px",
                      marginTop: "25px",
                    }}
                  />
                  <h5
                    className={classes.description}
                    style={{
                      fontFamily: i18n.language === "ar" ? "lfFont" : null,
                    }}
                  >
                    {t("values.description1")}
                  </h5>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <GrOptimize
                    style={{
                      color: "#3C4858",
                      fontSize: "24px",
                      marginTop: "25px",
                    }}
                  />
                  <h5
                    className={classes.description}
                    style={{
                      fontFamily: i18n.language === "ar" ? "lfFont" : null,
                    }}
                  >
                    {t("values.description2")}
                  </h5>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <GrGroup
                    style={{
                      color: "#3C4858",
                      fontSize: "24px",
                      marginTop: "25px",
                    }}
                  />
                  <h5
                    className={classes.description}
                    style={{
                      fontFamily: i18n.language === "ar" ? "lfFont" : null,
                    }}
                  >
                    {t("values.description3")}
                  </h5>
                </GridItem>
              </GridContainer>
            </Fade>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer justify="center" style={{ marginTop: "50px" }}>
            <GridItem xs={12} sm={12} md={6}>
              <Fade left delay={250}>
                <h3
                  className={classes.title}
                  style={{
                    fontFamily: i18n.language === "ar" ? "lfFont" : null,
                  }}
                >
                  {t("app.header")}
                </h3>
                <h5
                  className={classes.description}
                  style={{
                    fontFamily: i18n.language === "ar" ? "lfFont" : null,
                  }}
                >
                  {t("app.description")}
                </h5>
                <div>
                  <Button className="appStore"></Button>
                  <Button className="googlePlay"></Button>
                </div>
              </Fade>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Fade right delay={250}>
                <img src={mobileApp} style={{ width: "100%" }} />
              </Fade>
            </GridItem>
          </GridContainer>
          {/* <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Fade left delay={250}>
                <InfoArea
                  title="Free Chat"
                  description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                  icon={Chat}
                  iconColor="info"
                  vertical
                />
              </Fade>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Fade bottom delay={250}>
                <InfoArea
                  title="Verified Users"
                  description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                  icon={VerifiedUser}
                  iconColor="success"
                  vertical
                />
              </Fade>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Fade right delay={250}>
                <InfoArea
                  title="Fingerprint"
                  description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
                  icon={Fingerprint}
                  iconColor="danger"
                  vertical
                />
              </Fade>
            </GridItem>
          </GridContainer> */}
        </div>
      </div>
    </>
  );
};

export default AboutUs;
