import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "./i18n";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import Home from "views/HomePage/HomePage.js";
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import ScrollToTop from "views/ScrollToTop.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <ScrollToTop>
      <Switch>
        <Route path="/landing-page" component={LandingPage} />
        <Route path="/profile-page" component={ProfilePage} />
        <Route path="/login-page" component={LoginPage} />
        <Route path="/old" component={Components} />
        <Route path="/home" component={Home} />
        <Route path="/" component={Home} />
      </Switch>
    </ScrollToTop>
  </Router>,
  document.getElementById("root")
);
