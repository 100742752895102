import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";

import "views/HomePage/Sections/ContactUs.css";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

const ContactUs = () => {
  const classes = useStyles();
  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [phoneFocus, setPhoneFocus] = React.useState(false);
  const [messageFocus, setMessageFocus] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    console.log(email);
  }, [email]);

  return (
    <>
      <a id="contact" name="contact"></a>
      <div
        style={{
          backgroundColor: "#fafafa",
          borderBottomRightRadius: "6px",
          borderBottomLeftRadius: "6px",
        }}
      >
        <GridContainer style={{ marginTop: "85px" }} justify="center">
          <GridItem xs={12} sm={12} md={8} style={{ textAlign: "center" }}>
            <Fade top delay={250}>
              <h2
                className={classes.title}
                style={{
                  fontFamily: i18n.language === "ar" ? "lfFont" : null,
                }}
              >
                {t("contactus.header")}
              </h2>
            </Fade>
          </GridItem>
        </GridContainer>
        <div
          className="container"
          style={{
            borderBottomRightRadius: "6px",
            borderBottomLeftRadius: "6px",
          }}
        >
          <GridContainer>
            <span className="big-circle">
              <Slide left>
                <span></span>
              </Slide>
            </span>
            <img src="img/shape.png" className="square" alt="" />
            <Zoom>
              <div
                className="form"
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                style={{
                  fontFamily: i18n.language === "ar" ? "lfFont" : null,
                }}
              >
                <div className="contact-info">
                  <h3
                    className="title"
                    style={{
                      fontFamily: i18n.language === "ar" ? "lfFont" : null,
                    }}
                  >
                    {t("contactus.cardHeader")}
                  </h3>
                  <p className="text">{t("contactus.cardDescription")}</p>

                  <div className="info">
                    {/* <div className="information">
                      <img src="img/location.png" className="icon" alt="" />
                      <p>92 Cherry Drive Uniondale, NY 11553</p>
                    </div> */}
                    <div
                      className="information"
                      dir={i18n.language === "ar" ? "ltr" : "ltr"}
                    >
                      <img src="img/email.png" className="icon" alt="" />
                      <p>{t("contactus.cardEmail")}</p>
                    </div>
                    <div
                      className="information"
                      dir={i18n.language === "ar" ? "ltr" : "ltr"}
                    >
                      <img src="img/phone.png" className="icon" alt="" />
                      <p>{t("contactus.cardPhone")}</p>
                    </div>
                  </div>

                  <div className="social-media">
                    <p
                      style={
                        i18n.language === "ar"
                          ? {
                              display: "flex",
                              justifyContent: "end",
                              fontFamily: "lfFont",
                            }
                          : null
                      }
                    >
                      {t("contactus.cardConnect")} :
                    </p>
                    <div className="social-icons">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="contact-form">
                  <span className="circle one"></span>
                  <span className="circle two"></span>

                  <form action="index.html" autoComplete="off">
                    <h3
                      className="title"
                      style={{
                        fontFamily: i18n.language === "ar" ? "lfFont" : null,
                      }}
                    >
                      {t("contactus.formHeader")}
                    </h3>
                    <div
                      className={
                        nameFocus ? "input-container focus" : "input-container"
                      }
                    >
                      <input
                        type="text"
                        name="name"
                        className="input"
                        onFocus={() => {
                          setNameFocus(true);
                        }}
                        onBlur={() => {
                          setNameFocus(false);
                        }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      {name && !nameFocus ? (
                        <label htmlFor=""></label>
                      ) : (
                        <label htmlFor="">{t("contactus.input1")}</label>
                      )}
                      <span>{t("contactus.input1")}</span>
                    </div>
                    <div
                      className={
                        emailFocus ? "input-container focus" : "input-container"
                      }
                    >
                      <input
                        type="email"
                        name="email"
                        className="input"
                        value={email}
                        onFocus={() => {
                          setEmailFocus(true);
                        }}
                        onBlur={() => {
                          setEmailFocus(false);
                        }}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      {email && !emailFocus ? (
                        <label htmlFor=""></label>
                      ) : (
                        <label htmlFor="">{t("contactus.input2")}</label>
                      )}
                      <span>{t("contactus.input2")}</span>
                    </div>
                    <div
                      className={
                        phoneFocus ? "input-container focus" : "input-container"
                      }
                    >
                      <input
                        type="tel"
                        name="phone"
                        className="input"
                        onFocus={() => {
                          setPhoneFocus(true);
                        }}
                        onBlur={() => {
                          setPhoneFocus(false);
                        }}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                      {phone && !phoneFocus ? (
                        <label htmlFor=""></label>
                      ) : (
                        <label htmlFor="">{t("contactus.input3")}</label>
                      )}
                      <span>{t("contactus.input3")}</span>
                    </div>
                    <div
                      className={
                        messageFocus
                          ? "input-container focus"
                          : "input-container"
                      }
                    >
                      <textarea
                        name="message"
                        className="input"
                        onFocus={() => {
                          setMessageFocus(true);
                        }}
                        onBlur={() => {
                          setMessageFocus(false);
                        }}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                      ></textarea>
                      {message && !messageFocus ? (
                        <label htmlFor=""></label>
                      ) : (
                        <label htmlFor="">{t("contactus.input4")}</label>
                      )}
                      <span>{t("contactus.input4")}</span>
                    </div>
                    <input
                      type="submit"
                      value={t("contactus.btn")}
                      className="btn"
                      style={{
                        fontFamily: i18n.language === "ar" ? "lfFont" : null,
                      }}
                    />
                  </form>
                </div>
              </div>
            </Zoom>
          </GridContainer>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
