import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import Fade from "react-reveal/Fade";

// images
import ser1 from "assets/img/services/01.jpg";
import ser2 from "assets/img/services/02.jpg";
// import ser3 from "assets/img/services/03.jpg";
import ser4 from "assets/img/services/04.jpg";
import ser5 from "assets/img/services/05.jpg";
import ser6 from "assets/img/services/06.jpg";
import ser7 from "assets/img/services/07.jpg";
import ser8 from "assets/img/services/08.jpg";
import ser9 from "assets/img/services/09.jpg";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

const Services = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const FlipCard = ({ title, desc, image, delay }) => (
    <Flippy
      flipOnHover={false} // default false
      flipOnClick={false} // default false
      flipDirection="horizontal"
      style={{
        width: "500px",
        height: "116px",
        cursor: "default",
      }}
    >
      <Fade top delay={delay}>
        <FrontSide
          animationDuration={1000}
          style={{
            backgroundColor: "#41669d",
            borderRadius: "3px",
          }}
          className="servicesItem"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <h2
              style={{
                fontFamily: i18n.language === "ar" ? "lfFont" : null,
                color: "white",
                fontWeight: i18n.language === "ar" ? "300" : "600",
              }}
              className="testing"
            >
              {title}
            </h2>
          </div>
          <div
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "blur(2px)",
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: "-1",
            }}
          ></div>
        </FrontSide>
      </Fade>
      <BackSide
        style={{
          backgroundColor: "gray",
          borderRadius: "3px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        animationDuration={1000}
      >
        <h3>{desc}</h3>
      </BackSide>
    </Flippy>
  );

  const servicesArr = [
    {
      title: t("services.service1"),
      desc: "This is a Description",
      image: ser1,
    },
    {
      title: t("services.service2"),
      desc: "This is a Description",
      image: ser2,
    },
    {
      title: t("services.service3"),
      desc: "This is a Description",
      image: ser9,
    },
    {
      title: t("services.service4"),
      desc: "This is a Description",
      image: ser4,
    },
    {
      title: t("services.service5"),
      desc: "This is a Description",
      image: ser5,
    },
    {
      title: t("services.service6"),
      desc: "This is a Description",
      image: ser6,
    },
    {
      title: t("services.service7"),
      desc: "This is a Description",
      image: ser7,
    },
    {
      title: t("services.service8"),
      desc: "This is a Description",
      image: ser8,
    },
  ];

  let ServicesCards = () => (
    <GridContainer justify="center">
      {servicesArr.map(({ title, desc, image }, key) => (
        <GridItem
          key={key}
          xs={12}
          sm={12}
          md={3}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <FlipCard
            title={title}
            desc={desc}
            image={image}
            delay={200 + key * 150}
          />
        </GridItem>
      ))}
    </GridContainer>
  );

  return (
    <>
      <a id="services" name="services"></a>
      <div className={classes.section}>
        <GridContainer
          style={{ marginTop: "20px" }}
          justify="center"
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
        >
          <GridItem xs={12} sm={12} md={8}>
            <h2
              className={classes.title}
              style={{
                fontFamily: i18n.language === "ar" ? "lfFont" : null,
              }}
            >
              {t("services.header")}
            </h2>
            <h5
              className={classes.description}
              style={{
                fontFamily: i18n.language === "ar" ? "lfFont" : null,
              }}
            >
              {t("services.description")}
            </h5>
          </GridItem>
        </GridContainer>
        <ServicesCards />
      </div>
    </>
  );
};

export default Services;
