import React from "react";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import Maroof from "assets/img/maroof.png";

const Social = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <a
          href="#"
          className="whatsapp social"
          style={{ color: "black" }}
          target="_blank"
        >
          <WhatsAppIcon fontSize="default" />
        </a>
        <a
          href="#"
          className="linkedin social"
          style={{ color: "black" }}
          target="_blank"
        >
          <LinkedInIcon fontSize="default" />
        </a>
        <a
          href="#"
          className="twitter social"
          style={{ color: "black" }}
          target="_blank"
        >
          <TwitterIcon fontSize="default" />
        </a>
        <a
          href="#"
          className="instagram social newInsta"
          style={{ color: "black" }}
          target="_blank"
        >
          <InstagramIcon fontSize="default" />
        </a>
        <a
          href="#"
          className="instagram social"
          style={{ color: "black" }}
          target="_blank"
        >
          <img src={Maroof} width="30" />
        </a>
      </div>
      <p>
        Copyright © {1900 + new Date().getYear()} La Facil | <a>Privacy</a> |{" "}
        <a>Terms</a> | <a>Security</a>
      </p>
    </div>
  );
};

export default Social;
