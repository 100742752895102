import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// react components for routing our app without refresh
import { useHistory } from "react-router-dom";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Slider from "views/HomePage/Sections/Slider.js";
import Footer from "components/Footer/Footer.js";
import ScrollToTop from "react-scroll-to-top";
import ReactLoading from "react-loading";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import AboutUs from "views/HomePage/Sections/AboutUs.js";
import Services from "views/HomePage/Sections/Services.js";
import ContactUs from "views/HomePage/Sections/ContactUs.js";

import logo from "assets/img/logo/lf-logo-01.png";

const useStyles = makeStyles(styles);

const HomePage = (props) => {
  const [loaded, setLoaded] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { ...rest } = props;
  const [toggleDrawer, setToggleDrawer] = React.useState(() => () => {});

  React.useEffect(() => {
    let body = document.querySelector("body");
    window.scrollTo(0, 0);
    disableBodyScroll(body);
    setInterval(() => {
      clearAllBodyScrollLocks();
      setLoaded(true);
    }, 6000);
  }, []);

  const handleTest = (e) => {
    setToggleDrawer(() => e);
  };

  return (
    <>
      {!loaded ? (
        <div
          style={{
            display: "flex",
            position: "absolute",
            left: "0",
            top: "0",
            zIndex: "9999",
            width: "100%",
            height: "100%",
            backgroundColor: "#0A0908",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading type="bars" color="#fff" />
        </div>
      ) : null}
      <div>
        <Header
          test={(e) => {
            handleTest(e);
          }}
          color="transparent"
          brand=""
          leftLinks={
            <div
              style={{
                display: "flex",
                justifyContent: `${
                  window.innerWidth < 1000 ? "center" : "flex-start"
                }`,
              }}
            >
              <img
                src={logo}
                width={90}
                onClick={() => {
                  history.push("/home");
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
          }
          rightLinks={
            <HeaderLinks
              toggle={() => {
                toggleDrawer();
              }}
            />
          }
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "dark",
          }}
          {...rest}
        />
        <ScrollToTop
          smooth
          style={{ zIndex: "9" }}
          component={<ExpandLessIcon />}
        />
        <Parallax
          image={require("assets/img/landing-bg.jpg")}
          style={{ height: "100vh", marginBottom: "100px" }}
        >
          <Slider />
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <AboutUs />
          </div>
          <div className={classes.container}>
            <Services />
          </div>
          <ContactUs />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
